<template>
<div>
  <team-audit :type="0" v-if="$route.query.diff == '1'"></team-audit>
  <team-accomplish :type="0" :diff="$route.query.diff" v-else :examine="true"></team-accomplish>
</div>
</template>

<script>
import TeamAudit from "components/TeamAudit";
import TeamAccomplish from "components/TeamAccomplish";
export default {
  name: "Audit",
  components: {
    TeamAudit,
    TeamAccomplish
  }
}
</script>

<style scoped>

</style>
